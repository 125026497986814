<template>
  <div class="wrapper">
    <div class="page-header page-header-small">
      <parallax
          class="page-header-image"
          style="background-image: url('img/AdobeStock_159363813.jpeg')"
      />
      <div class="content-center">
        <div class="row">
          <div class="col-md-8 ml-auto mr-auto">
            <h1 class="title">
              TALENT
            </h1>
            <h4>所属タレント</h4>
          </div>
        </div>
      </div>
    </div>
    <div class="section">
      <div class="about-team team-4">
        <div class="container">
          <div class="row">
            <div class="col-md-8 ml-auto mr-auto text-center">
              <h2 class="title">
                1期生
              </h2>
              <h4 class="description">
                1st gen
              </h4>
            </div>
          </div>
          <div class="row">
            <div class="col-xl-6 col-lg-7 ml-auto mr-auto">
              <card
                type="profile"
                plain
              >
                <div
                  slot="raw-content"
                  class="row"
                >
                  <div class="col-md-5">
                    <div class="card-image">
                      <a href="/#/talents/yakoriru">
                        <img
                          class="img img-raised rounded"
                          src="img/fox-icon.png"
                        >
                      </a>
                    </div>
                  </div>
                  <div class="col-md-7">
                    <div class="card-body">
                      <h4 class="card-title">
                        八狐 リル
                      </h4>
                      <h6 class="category">
                        Yako Riru
                      </h6>
                      <p class="card-description">
                        Think in the morning. Act in the noon. Eat in the evening. Sleep in
                        the night......
                      </p>
                      <div class="card-footer">
                        <a
                            href="#pablo"
                            class="btn btn-icon btn-neutral btn-youtube btn-lg"
                        ><i
                            class="fab fa-youtube"
                        /></a>
                        <a
                          href="#pablo"
                          class="btn btn-icon btn-neutral btn-twitter btn-lg"
                        ><i
                          class="fab fa-twitter"
                        /></a>
                      </div>
                    </div>
                  </div>
                </div>
              </card>
            </div>
            <div class="col-xl-6 col-lg-7 ml-auto mr-auto">
              <card
                  type="profile"
                  plain
              >
                <div
                    slot="raw-content"
                    class="row"
                >
                  <div class="col-md-5">
                    <div class="card-image">
                      <a href="/#/talents/yakoriru">
                        <img
                            class="img img-raised rounded"
                            src="img/rabi-icon.png"
                        >
                      </a>
                    </div>
                  </div>
                  <div class="col-md-7">
                    <div class="card-body">
                      <h4 class="card-title">
                        八狐 リル
                      </h4>
                      <h6 class="category">
                        Yako Riru
                      </h6>
                      <p class="card-description">
                        Think in the morning. Act in the noon. Eat in the evening. Sleep in
                        the night......
                      </p>
                      <div class="card-footer">
                        <a
                            href="#pablo"
                            class="btn btn-icon btn-neutral btn-youtube btn-lg"
                        ><i
                            class="fab fa-youtube"
                        /></a>
                        <a
                            href="#pablo"
                            class="btn btn-icon btn-neutral btn-twitter btn-lg"
                        ><i
                            class="fab fa-twitter"
                        /></a>
                      </div>
                    </div>
                  </div>
                </div>
              </card>
            </div>
            <div class="col-xl-6 col-lg-7 ml-auto mr-auto">
              <card
                  type="profile"
                  plain
              >
                <div
                    slot="raw-content"
                    class="row"
                >
                  <div class="col-md-5">
                    <div class="card-image">
                      <a href="/#/talents/yakoriru">
                        <img
                            class="img img-raised rounded"
                            src="img/butterfly-icon.png"
                        >
                      </a>
                    </div>
                  </div>
                  <div class="col-md-7">
                    <div class="card-body">
                      <h4 class="card-title">
                        八狐 リル
                      </h4>
                      <h6 class="category">
                        Yako Riru
                      </h6>
                      <p class="card-description">
                        Think in the morning. Act in the noon. Eat in the evening. Sleep in
                        the night......
                      </p>
                      <div class="card-footer">
                        <a
                            href="#pablo"
                            class="btn btn-icon btn-neutral btn-youtube btn-lg"
                        ><i
                            class="fab fa-youtube"
                        /></a>
                        <a
                            href="#pablo"
                            class="btn btn-icon btn-neutral btn-twitter btn-lg"
                        ><i
                            class="fab fa-twitter"
                        /></a>
                      </div>
                    </div>
                  </div>
                </div>
              </card>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import { Card, Button, Badge, InfoSection, FormGroupInput, Checkbox } from '@/components';
  import { Select, Option } from 'element-ui'

  export default {
    name: 'AboutUs',
    bodyClass: 'about-us',
    components: {
      Card,
      InfoSection,
      Badge,
      [Button.name]: Button,
      [Checkbox.name]: Checkbox,
      [FormGroupInput.name]: FormGroupInput,
      [Select.name]: Select,
      [Option.name]: Option,
    },
    data() {
      return {
        form: {
          firstName: '',
          email: '',
          speciality: ''
        }
      }
    }
  }
</script>
<style>
</style>
