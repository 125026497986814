<template>
  <div class="wrapper bg_base base_container">
    <div class="container p-privacy">
      <div class="content-center">
          <div class="row base_title">
            <div class="text-center ml-auto mr-auto">
              <h2 class="title">
                PRIVACY POLICY
              </h2>
              <h3>
                プライバシーポリシー
              </h3>
            </div>
          </div>
          <div class="content_frame">
              <h4 class="text-left">
                個人情報の取り扱いについて
              </h4>
              <ul>
                <li>
                  <p>TRILLIONSTAGEは、皆様の個人情報の重要性を認識し、その適正な収集、利用、保護をはかるとともに、安全管理を行うため、プライバシーポリシーを定め、次のとおり運用します。</p>
                </li>
              </ul>
              <ul>
                <li>1）個人情報の定義</li>
                <li>このプライバシーポリシーにおいて、個人情報とは生存する個人に関する情報であって、以下のいずれかに該当するものをいいます。</li>
                <li>当該情報に含まれる氏名、生年月日その他の記述等｛文書、図画もしくは電磁的記録（電子的方式、磁気的方式そのほか人の知覚によって認識することができない方式により作られた記録をいう）に記載され、もしくは記録され、または音声、動作その他の方法を用いて表された一切の事項（個人識別符号を除く）をいう｝により、特定の個人を識別できるもの（他の情報と容易に照合でき、それにより特定の個人を識別できるものを含む）。<br>
                個人識別符号が含まれるもの。</li>
              </ul>
              <ul>
                <li>2）個人情報の管理</li>
                <li>個人情報の保護に関する運用要領を定めるとともに、個人情報の管理責任者を置いて適切に管理します。</li>
              </ul>
              <ul>
                <li>3）個人情報の収集</li>
                <li>個人情報の収集に際しては、あらかじめ利用目的を定め、お知らせするとともに、その目的の達成に必要な範囲内で、適正な方法により収集、利用いたします。また、「個人情報の保護に関する法律」（平成15年法律第57号。以下「個人情報保護法」とします）に定める場合を除き、あらかじめ本人の同意を得ることなく、収集した個人情報を利用目的以外には使用せず、利用目的達成後には当該個人情報を遅滞なく消去するよう努めるものといたします。</li>
              </ul>
              <ul>
                <li>4）個人情報の利用目的</li>
                <li>TRILLIONSTAGEは、以下に示す利用目的のために、皆様から個人情報をご提供いただく場合があります。下記以外の目的で個人情報を収集・利用する際は、前項に則り、利用目的をお知らせしたうえで収集いたします。
                  <ul class="frame-indent">
                    <li>① ご登録いただいた会員様向けサービスの提供のため</li>
                    <li>② ご注文、ご応募いただいた商品、賞品の発送のため</li>
                    <li>③ アンケート、イベント等にご協力いただいた方へのご報告のため</li>
                    <li>④ ダイレクトメールや電子メール等による、情報の提供のため</li>
                    <li>⑤ TRILLIONSTAGEのウェブサイトや各種ネットサービス（以下、「公式サイト等」とします）のサービス向上・改善、新しいサービス開発のため</li>
                    <li>⑥ 当社および関連会社等において取得した情報等により皆様の趣味・嗜好等を分析して、よりお客さまのニーズにあった商品、イベントに関するご連絡、お知らせ、ご案内等をするなど当社のサービス向上を図るため</li>
                    <li>⑦ 上記①～⑥の目的に関連する業務の遂行のため</li>
                  </ul>
                    公式サイト等においては、ご利用者様ご自身の判断により、TRILLIONSTAGEに対して個人情報を提供するか否かを選択できます。ただし、個人情報をTRILLIONSTAGEに対してご提供いただけない場合は、公式サイト等におけるサービスをご利用いただけないことがあります。
                </li>
              </ul>
              <ul>
                <li>5）個人情報の第三者提供</li>
                <li>ご本人から承諾を得たとき、個人情報保護法その他の法令等に基づく場合、または次のいずれかに該当する場合を除き、個人情報を第三者に提供することはありません。
                  <ul class="frame-indent">
                    <li>・人の生命、身体、または財産の保護のために個人データの第三者提供が必要である場合であって、本人の同意を得ることが困難である場合</li>
                    <li>・公衆衛生の向上または児童の健全な育成の推進のために特に必要がある場合であって、本人の同意を取ることが困難である場合</li>
                    <li>・国の機関もしくは地方公共団体またはその委託を受けた者が法令の定める事務を遂行することに対して協力する場合であって、本人の同意を得ることにより当該事務の遂行に支障を及ぼすおそれがある場合</li>
                    <li>・有料サービス利用や商品注文等に伴うクレジットカード等の決済手続きに際してユーザーの銀行口座やクレジットカード等の正当性を金融機関、カード会社等に確認する場合</li>
                  </ul>
                </li>
                <li>また、ご本人から承諾を得たとき、または個人情報保護法で認められている場合を除き、個人情報を海外の第三者に提供することはありません。</li>
              </ul>
              <ul>
                <li>6）個人情報の提供及び受領時の記録等</li>
                <li>個人情報を第三者（国の機関、地方公共団体、独立行政法人等及び地方独立行政法人を除き、以下、本項において同様とします）に提供し、または第三者から受領する場合は、個人情報保護法に従って、必要な事項について記録を作成するとともに、個人情報保護委員会規則で定められた期間保存いたします。</li>
              </ul>
              <ul>
                <li>7）個人情報保護のための安全管理措置</li>
                <li>個人情報の漏洩、滅失またはき損等を防止するために、必要かつ適切な組織的、人的、物理的、技術的安全管理措置を講じます。</li>
              </ul>
              <ul>
                <li>8）第三者への委託</li>
                <li>個人情報の処理を外部に委託する場合は、適正な委託先を選定するとともに個人情報保護に関する契約を委託先と取り交わし、委託先に対して必要かつ適切な監督を行います。</li>
              </ul>
              <ul>
                <li>9）個人情報の開示・訂正・利用停止又は消去</li>
                <li>ご本人から個人情報の内容の開示（個人データの第三者提供記録の開示を含みます）・訂正・利用停止・消去又は第三者提供の停止の請求があった場合は、個人情報保護法に基づき適切・迅速に対応いたします。</li>
              </ul>
              <ul>
                <li>10）プライバシーポリシーの変更</li>
                <li>TRILLIONSTAGEでは、社会情勢の変化、技術の進歩、個人情報保護法の改正等に応じ、事前の予告なくプライバシーポリシーを変更することがあります。</li>
              </ul>
              <ul>
                <li><p>なお、以上のポリシーは報道等を目的とする場合は適用されません。</p></li>
              </ul>
              <p class="text-right">2022年8月1日制定</p>
          </div>
      </div>
    </div>
  </div>
</template>
<script>
  import { Card, Button, Comment, Checkbox } from '@/components';

  export default {
    name: 'BlogPost',
    bodyClass: 'blog-post',
    components: {
      Card,
      Comment,
      [Button.name]: Button,
      [Checkbox.name]: Checkbox
    },
    data() {
      return {
        form: {
          comment: ''
        },
      }
    }
  }
</script>
<style>
</style>
