<template>
  <navbar
    position="fixed"
    :transparent="transparent"
    :color-on-scroll="colorOnScroll"
    menu-classes="ml-auto"
  >
    <!--<template>
      <router-link
        v-popover:popover1
        class="navbar-brand"
        to="/presentation"
      >
        <img src="/common/img/logo_trillionstage.svg" alt="TRILLIONSTAGE" width="200" height="50">
      </router-link>
      <el-popover
        ref="popover1"
        popper-class="popover"
        placement="bottom"
        width="200"
        trigger="hover"
      >
        <div class="popover-body">
          TOPページ
        </div>
      </el-popover>
    </template> -->
    <template slot="navbar-menu">
      <!--<div>
        <img src="common/img/top/logo_trillionstage.png" alt="TRILLIONSTAGE">
        </div> -->
      <li class="nav-item">
        <a
            class="nav-link"
            href="/#/presentation#about"
            target="_self"
        >
          <p>About</p>
        </a>
      </li>

      <li class="nav-item">
        <a
          class="nav-link"
          href="/#/presentation#news"
          target="_self"
        >
          <p>News</p>
        </a>
      </li>
      <!--<li class="nav-item">
        <a
            class="nav-link"
            href="/#/talents"
            target="_self"
        >
          <p>Talent</p>
        </a>
      </li> -->
      <!--<li class="nav-item">
        <a
            class="nav-link"
            href="#"
            target="_self"
        >
          <p>Schedule</p>
        </a>
      </li> -->
      <li class="nav-item">
        <a
            class="nav-link"
            href="/#/contact"
            target="_self"
        >
          <p>Contact</p>
        </a>
      </li>
      <!-- <li class="nav-item">
        <a
            class="nav-link"
            href="https://hololive.booth.pm/"
            target="_blank"
        >
          <p>Goods</p>
        </a>
      </li> -->
      <li class="nav-item">
        <a
            class="nav-link"
            href="/#/about/privacy"
            target="_blank"
        >
          <p>Privacy Policy</p>
        </a>
      </li>
      <li class="nav-item">
        <a
            class="nav-link"
            href="/#/audition"
            target="_self"
        >
          <p>Audition</p>
        </a>
      </li>
    </template>
  </navbar>
</template>

<script>
  import { DropDown, Navbar, NavLink } from '@/components';
  import { Popover } from 'element-ui'
  export default {
    name: 'MainNavbar',
    components: {
      DropDown,
      Navbar,
      NavLink,
      [Popover.name]: Popover
    },
    props: {
      transparent: Boolean,
      colorOnScroll: Number,
    }
  }
</script>

<style scoped>

</style>
